<template>
    <div v-loading.fullscreen.lock="loading">
        <client-stock-rate-search-bar-component
            add_auth="新增客户存储费率"
            :AllClient="fAllClient"
            @search="get_client_stock_rate_index"
            @addTeam="addTeam"
        ></client-stock-rate-search-bar-component>
        <common-table-component
            details_auth="客户存储费率详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_client_stock_rate"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_client_stock_rate_index"
        ></common-page-component>
        <client-stock-rate-edit-component
            edit_auth="修改客户存储费率"
            del_auth="删除客户存储费率"
            state_auth="修改存储费率状态"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :client_stock_rate_details_data="fclient_stock_rate_details_data"
            :AllClient="fAllClient"
            @exitClientStockRateDialog="dialogExit"
            @search="get_client_stock_rate_index"
            @show_edit="show_edit"
            @details_row="details_client_stock_rate"
        ></client-stock-rate-edit-component>
    </div>
</template>

<script>
import { client_stock_rate_index_request, client_stock_rate_details_request } from '@/network/business/ClientStockRate.js'
import { client_list_request } from '@/network/list.js'

import ClientStockRateSearchBarComponent from '@/components/business/ClientStockRate/ClientStockRateSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import ClientStockRateEditComponent from '@/components/business/ClientStockRate/ClientStockRateEditComponent'

export default {
    name: 'ClientStockRateView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                client_id: '',
                type: '',
                state: 0,
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'client_name',
                    label: '客户名称',
                    minWidth: '300px',
                },
                {
                    prop: 'type',
                    label: '费用类型',
                    minWidth: '130px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'success',
                                filter_data: '仓储费'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '仓租费'
                            }
                        } else if(d===2) {
                            return {
                                type: 'warning',
                                filter_data: '应收装卸费'
                            }
                        } else if(d===3) {
                            return {
                                type: 'warning',
                                filter_data: '应收装卸费(托盘)'
                            }
                        } else if(d===4) {
                            return {
                                type: 'warning',
                                filter_data: '应付装卸费'
                            }
                        }  else if(d===5) {
                            return {
                                type: 'success',
                                filter_data: '仓储费(托盘)'
                            }
                        }
                    }
                },
                {
                    prop: 'rate',
                    label: '费率',
                    minWidth: '150px',
                    editdata: (row) => {
                        if(row['type']===0)
                        {
                            return row['rate'] + ' 元/吨/天'
                        } else if(row['type']===1) {
                            return row['rate'] + ' 元/㎡/月'
                        } else if(row['type']===2) {
                            return row['rate'] + ' 元/吨/次'
                        } else if(row['type']===3) {
                            return row['rate'] + ' 元/托/次'
                        } else if(row['type']===4) {
                            return row['rate'] + ' 元/吨/次'
                        } else if(row['type']===5) {
                            return row['rate'] + ' 元/托/天'
                        }
                    }
                },
                {
                    prop: 'state',
                    label: '状态',
                    minWidth: '100px',
                    html: (row) => {
                        if(row['state']===0) {
                            return `<div class="cell"><span class="el-tag el-tag--success el-tag--plain">正常</span></div>`
                        } else {
                            return `<div class="cell"><span class="el-tag el-tag--danger el-tag--plain">禁用</span></div>`
                        }
                    }
                },
                {
                    prop: 'note',
                    label: '备注',
                    minWidth:   '300px',
                    show_overflow_tooltip: true
                }
            ],
            fclient_stock_rate_details_data: {},
            fAllClient: []
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_client_stock_rate_index(param = {}) {
            this.loading = true
            this.cond.client_id = param.client_id ?? this.cond.client_id
            this.cond.type = param.type ?? this.cond.type
            this.cond.state = param.state ?? this.cond.state
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            client_stock_rate_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_client_stock_rate(id) {
            this.fid = id
            this.fdialogFormVisible = true
            client_stock_rate_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fclient_stock_rate_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_all_client() {
            client_list_request({})
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_client_stock_rate_index()
        this.get_all_client()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        ClientStockRateSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        ClientStockRateEditComponent,
    },
    watch: {},
}
</script>

<style lang='less'></style>