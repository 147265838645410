<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="关联客户" prop="client_id">
                <el-select
                    v-model="form.client_id"
                    filterable
                    placeholder="选择客户"
                    class="clientc"
                    clearable
                >
                    <el-option
                        v-for="client in AllClient"
                        :key="client.name"
                        :label="client.name"
                        :value="client.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="费率类型" prop="type">
                <el-select
                    v-model="form.type"
                    placeholder="选择类型"
                    class="typec"
                    filterable
                    clearable
                >
                    <el-option
                        label="仓储费"
                        :value=0
                    ></el-option>
                    <el-option
                        label="仓储费(托盘)"
                        :value=5
                    ></el-option>
                    <el-option
                        label="仓租费"
                        :value=1
                    ></el-option>
                    <el-option
                        label="应收装卸费"
                        :value=2
                    ></el-option>
                    <el-option
                        label="应收装卸费(托盘)"
                        :value=3
                    ></el-option>
                    <el-option
                        label="应付装卸费"
                        :value=4
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="state">
                <el-select
                    v-model="form.state"
                    placeholder="选择状态"
                    class="statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['正常','已禁用']"
                        :key="v"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加费率</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'ClientStockRateSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                client_id: '',
                type: '',
                state: 0,
                page: 1,
            },
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
    },
    filters: {},
    props: {
        AllClient: {
            type: Array,
            default() {
                return []
            },
        },
        add_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>